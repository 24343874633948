@import './grid.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .simple-form-grid > div {
//   display: grid;
// }

.file-input {
  .previews > div {
    display: inline-block;
    margin-right: 1rem;
  }
}

table {
  td {
    &.main-cell {
      width: 100%;
    }
  }
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.clearfix {
  clear: both;
}

.col-first {
  padding-left: 0;
}
.col-last {
  padding-right: 0;
}

.cell-12 {
  width: 100%;
}

.cell-11 {
  width: 91.66666667%;
}

.cell-10 {
  width: 83.33333333%;
}

.cell-9 {
  width: 75%;
}

.cell-8 {
  width: 66.66666667%;
}

.cell-7 {
  width: 58.33333333%;
}

.cell-6 {
  width: 50%;
}

.cell-5 {
  width: 41.66666667%;
}

.cell-4 {
  width: 33.33333333%;
}

.cell-3 {
  width: 25%;
}

.cell-2 {
  width: 16.66666667%;
}

.cell-1 {
  width: 8.33333333%;
}
